var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-staff__table editable_table flex flex-col w-full"},[_c('v-data-table',{ref:"filterTable",staticClass:"a-table w-full capitalize",attrs:{"single-select":"","item-key":"id","headers":_vm.headers,"items":_vm.tableData,"footer-props":_vm.footerProps,"server-items-length":_vm.total,"data-cy":"episodeList","loading":_vm.loading,"options":_vm.options},on:{"click:row":_vm.rowClickHandler,"update:options":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateRange(item))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-xs font-semibold",class:_vm.getStatusColour(item)},[_vm._v(" "+_vm._s(_vm.getEpisodeStatus(item))+" ")])]}},{key:"item.locationProgram",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSiteSection(item))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getType(item))+" ")]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getClientName(item))+" ")]}},{key:"item.worker",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getWorker(item))+" ")]}},{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('a',{staticClass:"hover:text-primary mx-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.rowClickHandler(item)}}},[_c('fa-icon',{staticClass:"text-grey-2 ml-3",attrs:{"icon":"chevron-right"}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }