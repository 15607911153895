<template>
    <div>
        <ReportFilter
            :assessmentList="assessmentList"
            dense
            hide-details
            outlined
            clearable
            @setAssessmentFilter="setAssessmentFilter"
            @setAssessmentTypeFilter="setAssessmentTypeFilter"
            @AssessmentType="setAssessmentTypeFilter"
            @setStaffFilter="setStaffFilter"
            v-model="locationProgramFilter"
        />
        <div :class="{'disabled pointer-events-none': isDisabled}">
            <StaffReportTable
                :loading="loading"
                :items-per-page="itemsPerPage"
                :table-data="staffEndpoints"
                :date-range="dateRange"
                @download="download"
            />
        </div>
    </div>
</template>

<script>
import ReportFilter from '@/components/reports/partials/ReportFilter'
import ClientsSvg from '@/assets/icons/nav/icons_clients.svg'
import StaffReportTable from '@/components/reports/partials/StaffReportTable'
import { format, subDays } from 'date-fns'
import axios from '@/utils/axios'
import snakecaseKeys from 'snakecase-keys'
import FileMethods from '@/components/shared/mixins/fileMethods'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {mapState} from 'vuex'
import { downloadFile } from '@/utils/file.helpers'

export default {
    name: "Report",
    mixins: [ FileMethods, MessageDialog ],
    props: {
        headersAssessmentTable: {
            type: Array,
            default: () => []
        },
        source: {
            type: String,
            default: null
        },
        itemsPerPage: {
            type: Number,
            default: 10
        },
        loading: {
            type: Boolean,
            default: true
        },
        assessmentList: {
            type: Array,
            default: () => []
        },
        assessmentsData: {
            type: Array,
            default: () => []
        },
        staffEndpoints: {
            type: Array,
            default: () => []
        }
    },
    components: {
        ClientsSvg, StaffReportTable,ReportFilter
    },
    data() {
        return {
            defaultDateRange: [format(subDays(new Date(), 6), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
            dateRange: [],
            staffReportFilter: {
                staffId: null,
                assessmentType: null,
                startDate: null,
                endDate: null,
            },
            locationProgramFilter: {locationProgramId: ''},
        }
    },
    async beforeMount() {
        if (!this.$can('view', 'reports')) {
            return await this.$router.push({name: 'dashboard'})
        }
        this.dateRange = [...this.defaultDateRange]
    },
    emits: [
        'setAssessmentFilter',
        'setAssessmentTypeFilter'
    ],
    setup(_, { emit }) {
        const setAssessmentFilter = (dataFilter) => {
            emit('setAssessmentFilter', dataFilter)
        }
        const setAssessmentTypeFilter = (dataAssessmentFilter) => {
            emit('setAssessmentTypeFilter', dataAssessmentFilter)
        }
        return {
            setAssessmentFilter,
            setAssessmentTypeFilter
        }
    },
    methods: {
        resetFiltersHandler() {
            this.locationProgramFilter = {locationProgramId: '', }
            this.dateRange = [...this.defaultDateRange]
            this.filter.locationProgram = null
            this.filter.status = null
            this.filter.worker = null
        },
        async download(endPoint) {
            let payload = {
                startDate: this.staffReportFilter.startDate ?? null,
                endDate: this.staffReportFilter.endDate ?? null,
                locationProgramId:  null,
                outputAs:"csv",
                assessmentType: this.staffReportFilter.assessmentType
            }
            payload = snakecaseKeys(payload, { deep: true })
            axios({
                method: 'POST',
                url: endPoint,
                data: payload,
                responseType: 'blob'
            }).then((resp) => {
                downloadFile(resp)
                this.type = 'success'
                this.message = 'Successfully downloaded document'
            })
            .catch((err) => {
                console.log('document download error', err)
                this.type = 'error'
                this.message = 'Sorry, your file could not be downloaded at this time, please contact your system administrator'
            })
            .finally(() => {
                if (!this.showMessage) this.$emit('show-message', {message: this.message, type: this.type})
                else this.showMessage({duration: 5000})
            })
        },
        dateRangeInput(value) {
            this.dateRange = value
        },
        setStaffFilter(data){
            this.staffReportFilter = {...this.staffReportFilter, ...data}
        }
    },
    computed: {
        locationProgramId() {
            return this.locationProgramFilter?.locationProgramId
        },
        isDisabled() {
            return !this.dateRange.length || !this.locationProgramId
        },
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
        }),
    }
}
</script>
