<template>
    <div class="flex items-center mb-6 flex-wrap">
        <fa-icon icon="filter" class="text-sm text-black mr-4" />
        <div class="flex mr-5">
            <v-autocomplete
                v-model="dataFilter.staff"
                :items="workersByLocationProgram"
                v-if="$can('view', 'staff_assessments')"
                :dense="dense"
                :single-line="singleLine"
                :hide-details="hideDetails"
                :outlined="outlined"
                :clearable="clearable"
                label="Staff"
                class="w-150 mr-2"
                @change="changeStaff"
            ></v-autocomplete>
            <v-select
                :dense="dense"
                :single-line="singleLine"
                :hide-details="hideDetails"
                :outlined="outlined"
                :clearable="clearable"
                :items="assessmentList"
                label="Assessment Type"
                class="w-160"
                v-model="locationProgramId"
                @change="locationProgramChangeHandler"
            ></v-select>
        </div>
        <div class="w-160 mr-5">
            <date-range-picker
                v-model="dateRange"
                :outlined="true"
                :dense="true"
                :solo="false"
                :single-line="true"
                :maxDate="null"
                :minDate="null"
            ></date-range-picker>
        </div>
        <v-btn
            depressed
            class="mr-4"
            data-cy="resetFiltersHandler"
            @click="resetFiltersHandler"
        >Reset</v-btn>
    </div>
</template>

<script>
import DateRangePicker from '@/components/partials/DateRangePicker.vue'
import { mapState } from 'vuex'
import EpisodeTable from "@/components/episodes/partials/EpisodeTable"
import HttpMixin from "@/components/shared/mixins/httpMixin"
import { ClientHelpers } from '@/components/shared/mixins/clientMixins'
import BackendHelpers from "@/components/shared/mixins/backendHelpers"
import camelcaseKeys from "camelcase-keys"
import GET_USERS from '@/graphql/queries/getUsers.gql'
import EpisodesSvg from '@/assets/icons/nav/icons_episodes.svg'
import LocationProgramFilter from '@/components/shared/LocationProgramFilter'
import { subDays,format } from 'date-fns'

const DEFAULT_FILTER = {
    locationProgram: {
        locationId: null,
        locationProgramId: null
    },
    status: null,
    worker: null
}

export default {
    name: 'ReportFilter',
    mixins: [BackendHelpers, HttpMixin, ClientHelpers],
    components: { EpisodeTable, EpisodesSvg, LocationProgramFilter, DateRangePicker },
    props: {
        dense: {
            type: Boolean,
            default: false
        },
        singleLine: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: true
        },
        value: {
            type: Object,
            default: () => ({})
        },
        minDate: {
            type: String,
            default: '2019-12-01'
        },
        maxDate: {
            type: String,
            default: format(new Date(), 'yyyy-MM-dd')
        },
        label : {
            type: String,
            default: 'Date Range'
        },
        row: {
            type: Boolean,
            default: true
        },
        wrap: {
            type: Boolean,
            default: true
        },
        solo: {
            type: Boolean,
            default: true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        assessmentList: {
            type: Array,
            default: () => []
        },
        locationProgramId: {
			type: String,
			default: null
		}
    },
    data() {
        return {
            dataFilter: {
                startDate: null,
                endDate: null,
                locationProgramId: null,
                staff: null
            },
            dataAssessmentFilter: {
                startDate: null,
                endDate: null,
                locationProgramId: null,
                assessment: null
            },
            dateRangeValue: [],
            defaultDateRange: [format(subDays(new Date(), 6), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
            dateRange: [],
            staffList: [
                {
                    text: 'Staff',
                    value: null
                }
            ],
            users: [],
            filter: {
                ...DEFAULT_FILTER,
                locationProgram: {...DEFAULT_FILTER.locationProgram}
            },
        }
    },
    async beforeMount() {
        if (!this.$can('view', 'reports')) {
            return await this.$router.push({'name': 'dashboard'})
        }
        this.dateRange = [...this.defaultDateRange]
    },
    apollo: {
        users: {
            query: GET_USERS,
            update: ({ users }) => {
                return camelcaseKeys(users, {deep: true})
            },
        }
    },
    emits: [
        'setAssessmentFilter',
        'setAssessmentTypeFilter',
        'setStaffFilter'
    ],
    methods: {
        filterHandlerValue(value) {
            this.dateRangeValue = value
        },
        changeStaff(value) {
            this.dataFilter.staff = value
            this.$emit('setAssessmentFilter', this.dataFilter)
            this.$emit('setStaffFilter', {staffId : this.dataFilter.staff})
        },
        changeAssessment(value) {
            this.dataAssessmentFilter.assessment = value
            this.$emit('setAssessmentTypeFilter', this.dataAssessmentFilter)
            this.$emit('setStaffFilter', {assessmentType : this.dataAssessmentFilter.assessment})

        },
        changeSelect(value) {
            console.log("changeSelect", value)
            this.dataFilter.staff = value
            this.dataAssessmentFilter.assessment = value
            this.$emit('setAssessmentFilter', this.dataFilter)
            this.$emit('setAssessmentTypeFilter', this.dataAssessmentFilter)
        },
        loadFilter(query) {
            this.filter.worker = query.worker || DEFAULT_FILTER.worker
        },
        resetFiltersHandler() {
            this.dateRange = [...this.defaultDateRange]
        },
        locationProgramChangeHandler() {
            const locationProgram = {
                locationId: this.locationId,
                locationProgramId: this.locationProgramId
            }
            this.$emit('input', locationProgram)
            this.$emit('change', locationProgram)
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
        }),
        workersByLocationProgram() {
            if (!this.filter.locationProgram.locationProgramId) return this.workersByLoggedInUser
            return this.workersByLoggedInUser.filter(
                worker => worker.locationProgramIds.includes(this.filter.locationProgram.locationProgramId)
            )
        },
    },
    watch: {
        dateRange(){
            this.$emit('setStaffFilter', {startDate : this.dateRange[0], endDate: this.dateRange[1]})
        }
    }
}
</script>
