<template>
    <v-layout
        :row="row"
        :wrap="wrap"
    >
        <v-menu
            v-model="fromDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            ref="dateRangePicker"
        >
            <template v-slot:activator="{ on }">
                <v-text-field 
                    :dense="dense"
                    :solo="solo"
                    :single-line="singleLine"
                    hide-details
                    :outlined="outlined"
                    readonly 
                    class="text-grey-2 leading-normal"
                    :label="label"
                    :value="fromToDateDisp"
                    v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker 
                locale="en-in"
                :min="minDate"
                :max="maxDate"
                v-model="dateRangeValue"
                no-title
                scrollable
                range
                @input="setPickerValue"
            >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="fromDateMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.dateRangePicker.save(dateRangeValue)">OK</v-btn>
            </v-date-picker>
        </v-menu>
    </v-layout>
</template>

<script>
import { format } from 'date-fns'

export default {
    name: 'DateRangePicker',
    props: {
        value: {
            type: Array,
            default: () => []
        },
        minDate: {
            type: String,
            default: '2019-12-01'
        },
        maxDate: {
            type: String,
            default: format(new Date(), 'yyyy-MM-dd')
        },
        label : {
            type: String,
            default: 'Date Range'
        },
        row: {
            type: Boolean,
            default: true
        },
        wrap: {
            type: Boolean,
            default: true
        },
        outlined: {
            type: Boolean,
            default: true
        },
        dense: {
            type: Boolean,
            default: true
        },
        solo: {
            type: Boolean,
            default: true
        },
        singleLine: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            fromDateMenu: false,
            fromDateVal: null,
            dateRangeValue: []
        }
    },
    mounted() {
        this.dateRangeValue = this.value
    },
    methods: {
        setPickerValue(value) {
            this.$emit('input', value)
        }
    },
    computed: {
        fromToDateDisp() {
            if (!this.dateRangeValue || !this.dateRangeValue.length) return ''

            if (this.dateRangeValue.length > 1) {
                return `${format(new Date(this.dateRangeValue[0]), 'dd/MM/yy')} - ${format(new Date(this.dateRangeValue[1]), 'dd/MM/yy')}`
            }
            return `${format(new Date(this.dateRangeValue[0]), 'dd/MM/yy')} - `
        }
    },
    watch: {
        value() {
            this.dateRangeValue = this.value
        }
    }
}
</script>
