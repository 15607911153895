<template>
    <div class="c-staff__table editable_table flex flex-col w-full">
        <v-data-table
          :headers="headers"
          :items="tableData"
          :hide-default-footer="true"
          item-key="name"
        >
            <template class="" v-slot:item.download="{ item }">
                <span class="hidden">{{ item.staffName }}</span>
                <div
                    class="cursor-pointer group uppercase text-sm text-grey-2 hover:text-primary-light mr-4"
                    @click="downClick(item)"
                >
                    <fa-icon icon="download" class="group-hover:text-primary-light mr-1" />
                    <span class="font-semibold group-hover:text-primary-light text-xs">Download</span>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: 'ReportTable',
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
		dateRange: {
			type: Array,
			default: () => []
		},
		locationProgramId: {
			type: [String, Number],
			default: null
		}
    },
    data() {
        return {
            headers: [
                {
                    text: 'Name',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: 'Download',
                    value: 'download',
                }
            ],
            tableDataArr: [],
        }
    },
    methods: {
        downClick(item) {
            const data = {}
            if (item.name === 'Summary Report with CSM') data.consultation_with_csm = true
            
            this.$emit('download', { endPoint: item.endPoint, data })
        },
        rowClickHandler(item) {
            if (!item.to) return
            this.$router.push({ name: item.to })
        }
    }
}
</script>
