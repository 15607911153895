<template>
    <div class="c-staff__table editable_table flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            single-select
            item-key="id"
            :headers="headers"
            :items="tableData"
            :footer-props="footerProps"
            class="a-table w-full capitalize"
            :server-items-length="total"
            data-cy="episodeList"
            :loading="loading"
            :options="options"
            @click:row="rowClickHandler"
            @update:options="updatePagination"
        >
            <template class="" v-slot:item.startDate="{ item }">
                {{getDateRange(item)}}
            </template>
            <template class="" v-slot:item.status="{ item }">
                <div :class="getStatusColour(item)" class="text-xs font-semibold">
                    {{getEpisodeStatus(item)}}
                </div>
            </template>
            <template class="" v-slot:item.locationProgram="{ item }">
                {{getSiteSection(item)}}
            </template>
            <template class="" v-slot:item.type="{ item }">
                {{getType(item)}}
            </template>
            <template class="" v-slot:item.client="{ item }">
                {{getClientName(item)}}
            </template>
            <template class="" v-slot:item.worker="{ item }">
                {{getWorker(item)}}
            </template>
            <template class="" v-slot:item.editRecord="{ item }">
                <div class="text-right">
                    <a
                        href="#"
                        @click.prevent="rowClickHandler(item)"
                        class="hover:text-primary mx-2"
                    >
                        <fa-icon icon="chevron-right" class="text-grey-2 ml-3" />
                    </a>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import StatusBadge from '@/components/partials/StatusBadge'
import { format } from 'date-fns'
import { EpisodeHelpers } from '@/components/shared/mixins/episodeMixins'

export default {
    name: 'EpisodeTable',
    mixins: [ EpisodeHelpers ],
    components: {StatusBadge},
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        total: {
            type: Number,
            default: 5
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 10
        },
        sort: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            emptyText: 'No episodes found for the search criteria',
            duration: 3000,
            type: '',
            message: '',
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            headers: [
               // { text: 'id', align: 'left', value: 'episodeId', class: 'id--header' },
                { text: 'Date Range', align: 'left', value: 'startDate', class: 'date-range--header' },
                { text: 'Status', align: 'left', value: 'status', class: 'status--header' },
                { text: 'Site - Section', align: 'left', value: 'locationProgram', class: 'site-section--header' },
                { text: 'Type', align: 'left', value: 'type', class: 'type--header' },
                { text: 'Client', align: 'left', value: 'client', class: 'client--header' },
                { text: 'Assigned Worker', align: 'left', value: 'worker', class: 'worker--header' },
                { text: '', align: 'left', value: 'editRecord', fixed: true},
            ],
            loaded: false
        }
    },
    mounted() {
        this.loaded = true
    },
    methods: {
        getClientName(episode) {
            return episode.client ? `${episode.client.firstName} ${episode.client.lastName}` : ''
        },
        updatePagination(options) {
            if (!this.loaded) return

            this.$emit('update-pagination', options)
        },
        rowClickHandler(item) {
            item.startDate = format(new Date(item.startDate), 'yyyy-MM-dd')
            this.$store.commit('SET_CURRENT_EPISODE', item)
            this.$store.commit('SET_ASSIGNED_TO_ME', false)
            this.$router.push({name: 'episodes', params: {clientId: item.clientId}})
        },
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            siteSections: state => state.app.siteSections,
            episodeTypes: state => state.app.episodeTypes
        }),
        types() {
            return this.episodeTypes.map(episodeType => ({
                value: episodeType.code,
                text: episodeType.name
            }))
        },
        episodeStatuses() {
            return this.staticChoices['episode.status']
        },
        options() {
            const sortBy = []
            const sortDesc = []
            if (this.sort?.length) {
                this.sort.forEach((sortOne) => {
                    const [fieldName, sortDir] = sortOne.split(' ')
                    sortBy.push(fieldName)
                    sortDesc.push(sortDir === 'desc')
                })
            }
            return {
                page: this.offset / this.limit + 1,
                itemsPerPage: this.limit,
                sortBy,
                sortDesc
            }
        }
    }
}
</script>
